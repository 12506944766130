<template>
  <b-card title="Courier Receivables Aging Report">
        <!-- table -->
        <vue-good-table
          ref="courier_receivables_aging"
          mode="remote"
          :columns="columns"
          :rows="rows"
          :isLoading="isLoadingProp" 
          :rtl="direction"
          
        >
        <div slot="loadingContent">
          <b-spinner
            ref="spinner_one"
            variant="primary"
            class="mr-1"
            type="grow"
            style="width: 4rem; height: 4rem;"
          />
        </div>
        <div slot="table-actions">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            v-on:click="greet(allData)"
            >
            Download CSV
        </b-button>
       
        <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            v-on:click="reconciled"
            >
            Mark as Reconciled
        </b-button> -->
      </div>
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Name -->
            <!-- <span
              v-if="props.column.field === 'fullNamae'"
              class="text-nowrap"
            >
              <b-avatar
                :src="props.row.avatar"
                class="mx-1"
              />
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span> -->

            <!-- Column: Status -->
            <span v-if="props.column.field == 'less_than_two_days'">
              
              <b-button variant="link" v-on:click="goToDrillDown('less_than_two_days',props.row.courier_name)">  {{ props.row.less_than_two_days }} </b-button>
              
            </span>
            <span v-else-if="props.column.field == 'two_to_four_days'">
              
              <b-button variant="link" v-on:click="goToDrillDown('two_to_four_days',props.row.courier_name)">  {{ props.row.two_to_four_days }} </b-button>
              
            </span>
            <span v-else-if="props.column.field == 'greater_than_four'">
              
              <b-button variant="link" v-on:click="goToDrillDown('greater_than_four',props.row.courier_name)">  {{ props.row.greater_than_four }} </b-button>
              
            </span>
            

            <!-- Column: Action -->
            <!-- <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item>
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span> -->

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

         
        </vue-good-table>

    


    
    
  </b-card>
</template>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script type="text/javascript" src="libs/FileSaver/FileSaver.min.js"></script>
<script type="text/javascript" src="libs/js-xlsx/xlsx.core.min.js"></script>
<script type="text/javascript" src="tableExport.min.js"></script>
<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdownItem, BDropdown,BCard,BButton,BTabs,BTab,BSpinner,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import store from '@/store/index'
import pages from '@/router/routes/pages'
import Ripple from 'vue-ripple-directive'
import reportStore from '@/store'

export default {
  components: {
    VueGoodTable,
    BCard,
    BTab,
    BSpinner,
    BTabs,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BButton,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tabIndex: 0,
      allData:null,
      log: [],
      pageLength: 10,
      totalRecords:0,
      dir: false,
      isLoadingProp:true,
      pages: ['10','20','50','100'],
      columns: [
        // {
        //   label: 'Name',
        //   field: 'fullName',
        //   filterOptions: {
        //     styleClass: 'class1', // class to be added to the parent th element
        //     enabled: true, // enable filter for this column
        //     placeholder: 'Filter This Thing', // placeholder for filter input
        //     filterValue: '', // initial populated value for this filter
        //     filterDropdownItems: [], // dropdown (with selected values) instead of text input
        //     filterFn: this.columnFilterFn, //custom filter function that
        //     trigger: 'enter', //only trigger on enter not on keyup 
        //   },
        // },
        {
          label: 'Courier',
          field: 'courier_name',
          sortable: false
        },
        {
          label: '<2 Days',
          field: 'less_than_two_days',
          sortable: false
        },
        {
          label: '2-4 Days',
          field: 'two_to_four_days',
          sortable: false
        },
        {
          label: '4+ Days',
          field: 'greater_than_four',
          sortable: false
        },
      ],
      rows: [],
      
      
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Lost      : 'light-primary',
        Delivered : 'light-success',
        Return     : 'light-danger',
        Losts    : 'light-warning',
        Losst      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return shipment_status => statusColor[shipment_status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
        this.isLoadingProp = true;
      this.$http.get('get-courier-receivables-aging')
        .then(res => { this.rows = res.data.data;
        this.updateAllData(res.data.data);
        this.isLoadingProp = false;
        })
    
  },
  
  methods: {
    updateAllData(data){
      this.allData = null;
      this.allData = data;
    },
    goToDrillDown: function(topic,courier){
        reportStore.commit('report/SET_MAIN_TOPIC','courier-receivables-aging');
        reportStore.commit('report/SET_SUB_TOPIC',topic);
        reportStore.commit('report/SET_COURIER_TOPIC',courier);

        this.$router.push({ name: 'drilldown-report' })
        // console.log(reportStore.state.report.subTopic);
    },

    greet: function (arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
      // $('.vgt-table bordered').tableExport({type:'csv'});
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>